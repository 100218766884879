<template>
  <div>
    <!-- <div v-if="!isMobile" class="submit-message w-100 mt-5 mb-2">Select Plot and Continue</div> -->
    <div class="card-info mt-2">
      <div class="submit-button-section">
        <div class="submit-button-card">
          <!-- <span v-if="isMobile" class="submit-message">Select Plot and Continue</span> -->
          <router-link @click.native="navigate" :to="{ name: 'Floorplan', query: { ...$route.query } }"
            class="btn btn-block generate-eoi">Continue</router-link>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center carousel-container">
      <div class="gallery-title">Photo <span class="gallery-title-bld"> Gallery</span></div>
      <div class="typology-carousel-overlay"></div>
      <carousel ref="carousel" class="typology-carousel" :per-page="1" :autoplay="false" :pagination-enabled="false"
        :navigation-enabled="true" :loop="true" :mouse-drag="false" :navigation-prev-label="prevLabel"
        :navigation-next-label="nextLabel">
        <slide v-for="slide in slideImages" :key="slide.key">
          <a v-if="slide.image" :href="slide.image" data-fancybox="gallery">
            <img :src="slide.image" alt="" class="img-fluid m-0 carousel-img">
          </a>
        </slide>
      </carousel>
    </div>
    <button @click="$router.push({ name: 'Home', query: { ...$route.query } })" type="button" class="prevButton">
      <img src="@/assets/images/back-arrow.svg" alt="Back" height="16" width="16">
      Back</button>
  </div>
</template>
<script>
import { masterJson } from '@/assets/scripts/utility.js';
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide,
    DownArrow: () => import('@/components/Common/DownArrow.vue')
  },
  data() {
    return {
      prevLabel: '',
      nextLabel: '',
      isDownVisible: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.carousel.$forceUpdate();
      this.$refs.carousel.computeCarouselWidth();
    }, 300);
    if (this.isMobile) {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
  },
  unmounted() {
    if (this.isMobile) {
      window.removeEventListener('scroll', this.handleScroll, { passive: true });
    }
  },
  watch: {
    isGifLoading(newValue) {
      if (!newValue) {
        this.$refs.carousel.$forceUpdate();
      }
    }
  },
  methods: {
    selectTypology(typology) {
      this.$store.dispatch('setTypology', typology);
    },
    navigate() {
      this.$store.dispatch('setLoading', true);
    },
    handleScroll(event) {
      if (window.innerHeight + window.scrollY < document.body.offsetHeight) {
        this.isDownVisible = true;
      } else {
        this.isDownVisible = false;
      }
    }
  },
  computed: {
    slideImages() {
      return masterJson.Buildings.filter(building => building.image && building.status === "Available");
    },
    typologies() {
      return masterJson.Buildings;
    },
    selectedTypology() {
      return this.$store.getters.selectedTypology;
    },
    isGifLoading() {
      return this.$store.getters.isGifLoading;
    },
    isMobile() {
      return window && window.innerWidth <= 991;
    }
  }
}
</script>
<style scoped>
.generate-eoi {
  background: #444444;
  border-radius: 4px;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  font-weight: 600;
  padding: 15px 15px 16px 15px;
  box-shadow: none;
}

.generate-eoi:hover {
  /* background: #0692f0; */
  /* color: #FFFFFF; */
}

.card-info {

  background: #FFFFFF;
  border: 1px solid rgba(73, 72, 153, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  margin: 16px;
  margin-left: 0;
  margin-right: 0;
  padding: 16px;
  margin-top: 40px;
}

.side-menu.typology {
  min-height: auto !important;
}

.outer-wrapper {
  margin: 0;
  padding-top: 30px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 11.68%, #10020F 25.64%);

}

.plan-holder {
  border-radius: 10px;
  height: 650px;
  padding: 0;
}

.plan-holder h3 {
  font-weight: 600;
  font-size: 18px;
  color: #082D57;
  border-bottom: 2px solid #F5F5F5;
  padding: 20px;
}

.side-menu {
  min-height: 650px;
}

.brand-logo {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  margin-bottom: 20px;
}

h3 {
  font-weight: 600;
  font-size: 18px;
  color: #082D57;
  border-bottom: 2px solid #F5F5F5;
  padding: 20px;
}

.side-menu.typology {
  height: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 10px 0px 0px 10px;
  position: relative;
}

.side-menu.typology .list-group {
  flex-direction: column;
}

.side-menu.typology .list-group-item {
  padding: 5px 0;
}

select.form-control {
  background: url('../../assets/images/select-dropdown.svg');
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-position: right 10px top 10px;
  background-repeat: no-repeat;
}

.custom-control {
  margin-right: 0;
}

.custom-control-label {
  padding: 15px 15px 15px 40px;
}

.custom-control-label .divider {
  width: 5px;
  height: 100%;
  border-radius: 0px 4px 4px 0px;
}

.typology .custom-control-label::before,
.typology .custom-control-label::after {
  top: 17px;
  left: 15px;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  top: 20px;
  left: 18px;
}

.side-action {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  gap: 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background: #fff;
  border-radius: 10px;
}

.prevButton {
  color: rgba(0, 0, 0, 0.5);
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  margin-top: 30px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: 0.3s;
  padding: 0;
}

.prevButton:hover {
  gap: 10px;
}

.side-action .btn-primary {
  background: #082D57;
  border-radius: 4px;
  padding: 15px;
}

.side-action .btn-primary:disabled {
  opacity: 1;
}

.side-action p {
  font-weight: 400;
  font-size: 9px;
  color: #082D57;
  opacity: 0.5;
  text-align: center;
}

.form-wrapper {
  padding: 0;
}

.form-wrapper label {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #082D57;
  opacity: 0.5;
}

.sub-head {
  font-weight: 600;
  font-size: 13px;
  color: #082D57;
  display: block;
  line-height: 16px;
}

.total-floors-section img {
  margin-right: 16px;
}

.sub-head-label {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 6px;
  color: #00000080;
}

.card-foot {
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
}

.card-footer-head span {
  letter-spacing: -0.01em;
  color: #082D57;
  opacity: 0.5;
  font-weight: 500;
  font-size: 12px;
}

h5 {
  font-weight: 600;
  font-size: 15px;
  color: #082D57;
}

.link-btn {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.typology-carousel-overlay {
  background: linear-gradient(133.4deg, rgba(0, 0, 0, 0.5) 12.76%, rgba(0, 0, 0, 0) 32.4%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 10px;
  pointer-events: none;
}

.typology-carousel {
  border-radius: 10px;
}

.carousel-container {
  position: relative;
}

.gallery-title {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-top: 16px;
  padding-left: 16px;
}

.carousel-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.gallery-title span {
  font-weight: 800;
}

.submit-message {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: inline-block;
  margin-bottom: 16px;
}

@media (max-width:991px) {
  .prevButton {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .card-info {
    border-radius: 0;
    margin-top: 0;
    padding: 0;
    margin-bottom: 0;
  }

  .typology-carousel {
    width: 100%;
    overflow-x: hidden;
    border-radius: 0;
  }

  .typology-carousel-overlay {
    border-radius: 0;
  }

  .submit-button-section {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1003;
    width: 100%;
  }

  .submit-button-card {
    padding: 16px;
    background: #ffffff;
    width: 100%;
    border: 1px solid rgba(73, 72, 153, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    text-align: center;
  }

  .generate-eoi {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
</style>